@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	@apply bg-neutral-900 text-neutral-100;
}

ul::-webkit-scrollbar {
	height: 5px;
}

ul::-webkit-scrollbar-track {
	@apply bg-transparent
}

ul::-webkit-scrollbar-thumb {
	@apply bg-neutral-700;
	@apply transition-all duration-200 ease-in-out;
}

ul::-webkit-scrollbar-thumb:hover {
	@apply bg-neutral-600;
}